/* !
 * setting.js v1.0.0
 * Copyright, Connecty Inc.
 */
(() => {
	const siteName = 'pokkasapporo-fb';
	const htmlHasClass = (_class) => { return document.documentElement.classList.contains(_class); };
	const a = 'is-active';
	const v = 'is-visible';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			isEnglish: location.pathname.indexOf('/english/') !== -1 ? true : false,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			header: {
				desktop: 20,
				sp: 60
			}
		},
		localDecision() {
			const regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.|100\\.64\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading() { // ローカル環境ならincludeをAjaxで読み込む
			const _this = this;
			const key = 'inc_';
			let ajaxArray = [];
			let ajaxArrayList = [];
			let includeClass = document.querySelectorAll(`[class*="${key}"]`);

			includeClass.forEach(ele => {
				let path = ele.innerHTML.split(' ')[1];
				if (path.indexOf('inc_') !== -1) {
					ajaxArray.push(path);
				}
			});

			if (ajaxArray.length) {
				ajaxArray.forEach(ele => {
					let xhr = new XMLHttpRequest();
					let promise = new Promise((resolve, reject) => {
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								if (xhr.status === 200) {
									resolve(xhr.responseText);
								} else {
									reject(xhr.statusText);
								}
							}
						};
					});
					xhr.open('GET', ele + '.html', true);
					xhr.send();
					ajaxArrayList.push(promise);
				});

				Promise.all(ajaxArrayList).then(results => {
					let regExp = new RegExp(key);

					results.forEach(ele => {
						let classList = ele.split(/ |\"/g);
						let position;

						classList.forEach(ele2 => {
							if (ele2.match(regExp)) { position = ele2; }
						});
						document.querySelectorAll('.' + position).forEach(ele2 => {
							ele2.outerHTML = ele;
						});
						console.log('Succeeded to read the include file!:', position);
					});

					_this.loadDelayScript();
				}).catch((error) => {
					console.error('Failed to read the include file:', error);
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		getQuery() {
			let vars = [];
			let hash = null;
			let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			hashes.forEach(ele => {
				hash = ele.split('=');
				hash[1] = (hash[1].indexOf('#') !== -1) ? hash[1].split('#')[0] : hash[1];
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			});
			return vars;
		},
		accordion() {
			const setAccordion = (_e, _ele, _wrapper, _plus) => {
				_e.preventDefault();
				if (_ele.open) {
					const close = _wrapper.animate(
						{
							opacity: [1, 0],
							height: [_wrapper.offsetHeight + 'px', 0],
						},
						{
							duration: 300,
							easing: 'ease',
						}
					);
					_plus.animate(
						{ rotate: ['90deg', '0deg'] },
						{
							duration: 300,
							pseudoElement: "::after",
							easing: 'ease',
							fill: 'forwards',
						}
					);
					close.onfinish = () => {
						_ele.removeAttribute('open');
					}
				} else {
					_ele.setAttribute('open', 'true');
					_wrapper.animate(
						{
							opacity: [0, 1],
							height: [0, _wrapper.offsetHeight + 'px'],
						},
						{
							duration: 300,
							easing: 'ease',
						}
					);
					_plus.animate(
						{ rotate: ['0deg', '90deg'] },
						{
							duration: 300,
							pseudoElement: "::after",
							easing: 'ease',
							fill: 'forwards',
						}
					);
				}
			};

			const details = document.querySelectorAll('.mod-accordion');
			details.forEach(ele => {
				const summary = ele.querySelector('summary');
				const wrapper = ele.querySelector('.mod-accordion-wrapper');
				const plus = ele.querySelector('.mod-accordion-plus');
				if (ele.open) { // 初期open
					plus.animate(
						{ rotate: ['0deg', '90deg'] },
						{
							pseudoElement: "::after",
							fill: 'forwards',
						}
					);
				}
				summary.addEventListener('click', e => {
					setAccordion(e, ele, wrapper, plus);
				});
			});

			// PCでは初期表示で sp-accordion を開いた状態にする
			if (FUNCTIONS.va.window.width >= FUNCTIONS.va.device.desktop) {
				document.querySelectorAll('.sp-accordion').forEach(ele => {
					ele.setAttribute('open', true);
				});
			}
		},
		pulldown() {
			const setPulldown = (_e, _ele, _summary, _content, _h) => {
				const option = { duration: 300, easing: 'ease', fill: 'forwards' };
				const option_content = { duration: 300, easing: 'ease' };

				if (_e) { _e.preventDefault(); }

				if (_ele.open) {
					const close = _content.animate({
						opacity: [1, 0],
						height: [_content.offsetHeight + 'px', 0],
					}, option_content);

					_ele.animate({ height: [(_summary.offsetHeight + _content.offsetHeight + 3) + 'px', _h] }, option);

					close.onfinish = () => {
						_ele.removeAttribute('open');
					}
				} else {
					_ele.setAttribute('open', 'true');
					_ele.animate({ height: [_h, (_summary.offsetHeight + _content.offsetHeight + 3) + 'px'] }, option);

					_content.animate({
						opacity: [0, 1],
						height: [0, _content.offsetHeight + 'px'],
					}, option_content);
				}
			};
			const details = document.querySelectorAll('.mod-pulldown > details');
			details.forEach(ele => {
				const summary = ele.querySelector('summary');
				const content = ele.querySelector('.mod-pulldown-content');
				let h = ele.offsetHeight + 'px';
				summary.addEventListener('click', e => {
					setPulldown(e, ele, summary, content, h);
				});
			});
			document.addEventListener('click', e => {
				if (!e.target.closest('.mod-pulldown')) {
					details.forEach(ele => {
						if (ele.open) {
							setPulldown(false, ele, ele.querySelector('summary'), ele.querySelector('.mod-pulldown-content'), ele.closest('.mod-pulldown').offsetHeight + 'px')
						}
					});
				}
			});
		},
		tabSwitch() {
			let $tab = document.querySelectorAll('.js-tab');
			let $content = document.querySelectorAll('.js-tab-content');
			if ($tab.length) {
				// ハッシュタグで絞り込み
				if (location.hash) {
					let hash = location.hash.split('#')[1];
					$tab.forEach(tab => {
						tab.ariaSelected = false;
						if (tab.getAttribute('data-target').match(hash)) {
							tab.ariaSelected = true;
						}
					});
					$content.forEach(ele => {
						ele.classList.remove(a);
						ele.classList.remove(v);
						if (ele.getAttribute('data-target').match(hash)) {
							ele.classList.add(a);
							setTimeout(() => { ele.classList.add(v); }, 200);
						}
					});
				}

				$tab.forEach(ele => {
					ele.addEventListener('click', () => {
						$tab.forEach(tab => {
							tab.classList.remove(a);
							tab.ariaSelected = false;
						})
						ele.classList.add(a);
						ele.ariaSelected = true;

						$content.forEach(content => {
							content.classList.remove(a);
							content.classList.remove(v);
						});

						let $target;
						let target = ele.getAttribute('data-target');
						if (target === 'all') {
							$target = $content;
						} else {
							$target = document.querySelectorAll('[data-target="' + target + '"]');
						}
						$target.forEach(target => {
							target.classList.add(a);
							setTimeout(() => { target.classList.add(v); }, 200);
						});
					});
				});
			}
		},
		scrollToObject(_target) {
			let hash = (_target.currentTarget) ? _target.currentTarget.href.split('#')[1] : _target.slice(1);
			if (hash === 'pagetop' || document.getElementById(hash)) {
				let pos = hash === 'pagetop' ? 0 : document.getElementById(hash).getBoundingClientRect().top;
				let offset = hash === 'pagetop' ? 0 : window.scrollY - 20;
				pos += offset;
				window.scrollTo({ top: pos, behavior: 'smooth' });
			}
		},
		waitForScrollComplete (_hash) {
			// フォーカス用関数
			const scrollFocus = (_hash) => {
				let hash = _hash;
				if (hash !== 'pagetop') {
					const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
					if (document.getElementById(hash).querySelectorAll(focusableElementsSelector).length) {
						const focusableElements = document.getElementById(hash).querySelectorAll(focusableElementsSelector);
						if (focusableElements.length) {
							focusableElements[0].focus({ preventScroll: true });
						}
					}
				}
			};
			let scrollResolveTimeout;
			return new Promise((resolve) => {
				const scrollTimeout = setTimeout(() => {
					resolve(true);
				}, 100);
				const scrollTimeoutEvent = addEventListener('scroll', function fn(e) {
					clearTimeout(scrollTimeout);
					clearTimeout(scrollResolveTimeout);
					scrollResolveTimeout = setTimeout(() => {
						scrollFocus(_hash);
						removeEventListener('scroll', fn);
						resolve(true);
					}, 100);
				});
			});
		},
		easeScroll() {
			// スクロール完了後に実行する関数
			document.querySelectorAll('a[rel="scroll"]').forEach((ele) => {
				ele.addEventListener('click', async (e) => {
					e.preventDefault();
					if (/#/.test(e.currentTarget.href)) {
						FUNCTIONS.scrollToObject(e);
						let hash = e.currentTarget.href.split('#')[1];
						await FUNCTIONS.waitForScrollComplete(hash);
						return false;
					}
				});
			});
		},
		easeScrollHash() {
			let hash = location.hash;
			if (hash && !document.querySelector('.prod-category')) {
				window.addEventListener('load', () => {
					hash = hash.split('#')[1];
					FUNCTIONS.scrollToObject(hash);
					FUNCTIONS.waitForScrollComplete(hash);
				});
			}
		},
		pageTop() {
			let $pagetop = document.querySelector('.footer-pagetop');
			if ($pagetop) {
				let options = {
					rootMargin: '0px',
					threshold: [0, 1],
				};
				const callbackTop = (entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							$pagetop.classList.remove('is-active');
						} else {
							$pagetop.classList.add('is-active');
						}
					});
				};
				const callbackBottom = (entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							$pagetop.classList.add('is-bottom');
						} else {
							$pagetop.classList.remove('is-bottom');
						}
					});
				};
				let observerTop = new IntersectionObserver(callbackTop, options);
				observerTop.observe(document.querySelector('.header'));
				let observerBottom = new IntersectionObserver(callbackBottom, options);
				if (!FUNCTIONS.va.isEnglish) {
					observerBottom.observe(document.querySelector('.footer-share'));
				} else {
					observerBottom.observe(document.querySelector('.footer'));
				}
			}
		},
		setModal() {
			const createModal = (_appendHTML,_target) => {
				const container = document.createElement('div');
				container.className = 'mod-modal-overlay';
				container.innerHTML = _appendHTML;
				document.body.appendChild(container);

				container.classList.add(a);
				setTimeout(() => {
					container.classList.add(v);
					container.querySelector('.mod-modalContent').style.height = container.querySelector('.mod-modalContent').offsetHeight + 'px';

					document.querySelectorAll('.mod-modal-closeArea, .mod-modal-closeBtn').forEach((ele) => {
						ele.addEventListener('click', (e) => {
							container.classList.remove(v);
							setTimeout(() => {
								container.classList.remove(a);
								container.remove();
								_target.focus();
							}, 400);
						});
					});
				});
			};
			document.querySelectorAll('.mod-modal').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let targetKey = e.currentTarget.getAttribute('data-target');
					let $content = document.querySelector(`.mod-modalContent[data-target="${targetKey}"]`);
					if ($content) {
						let closeArea = document.createElement('div');
						closeArea.className = 'mod-modal-closeArea';
						let containerDiv = document.createElement('div');
						containerDiv.appendChild(closeArea);
						containerDiv.appendChild($content.cloneNode(true));

						createModal(containerDiv.innerHTML, e.currentTarget);
					}
					return false;
				});
			});

			// 画像1個だけのモーダル
			document.querySelectorAll('.mod-modalImg').forEach((ele) => {
				ele.addEventListener('click', (e) => {
					let appendHTML = `<div class="mod-modal-closeArea"></div>
						<button type="button" class="mod-modal-closeBtn" aria-label="close"><span aria-hidden="true"></span></button>
						${e.currentTarget.querySelector('img').outerHTML}`;
					createModal(appendHTML,e.currentTarget);
					return false;
				});
			});

			// モーダル内のフォーカストラップ
			document.addEventListener('keydown', event => {
				if (document.querySelector('.mod-modal-overlay')) {
					// タブキーが押された時
					if (event.key === 'Tab') {
						event.preventDefault();
						// モーダル要素内のフォーカス可能な要素の一覧を取得
						const focusableElementsSelector = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]';
						const focusableElements = Array.from(
							document.querySelector('.mod-modal-overlay').querySelectorAll(focusableElementsSelector)
						);
						// 現在のフォーカス位置を取得
						const focusedItemIndex = focusableElements.indexOf(document.activeElement);
						// shiftキーと同時に押されてた場合
						if (event.shiftKey) {
							if (focusedItemIndex === 0) {
								// 現在のフォーカスが最初の要素の場合、最後の要素にフォーカスを移動
								focusableElements[focusableElements.length - 1].focus();
							} else {
								// 現在のフォーカスが最初の要素以外の場合、前の要素にフォーカスを移動
								focusableElements[focusedItemIndex - 1].focus();
							}
						} else {
							if (focusedItemIndex === focusableElements.length - 1) {
								// 現在のフォーカスが最後の要素の場合、最初の要素にフォーカスを移動
								focusableElements[0].focus();
							} else {
								// 現在のフォーカスが最後の要素以外の場合、次の要素にフォーカスを移動
								focusableElements[focusedItemIndex + 1].focus();
							}
						}
					}
					// ESCキーが押された時
					if (event.key === 'Escape') {
						event.preventDefault();
						document.querySelectorAll('.mod-modal-overlay').classList.remove(v);
						setTimeout(() => {
							document.querySelectorAll('.mod-modal-overlay').classList.remove(a);
							document.querySelectorAll('.mod-modal-overlay').remove();
							_target.focus();
						}, 400);
					}
				}
			});
		},
		matchHeight(_elements) {
			let dataFlg = false;
			if (!_elements) {
				_elements = document.querySelectorAll('[data-mh]');
				dataFlg = true;
			}

			_elements.forEach(ele => { ele.style.height = 'auto'; }); // 初期化

			if (dataFlg) {
				const groups = new Map();
				_elements.forEach(ele => {
					const attributeName = ele.getAttribute('data-mh');
					if (!groups.has(attributeName)) {
						groups.set(attributeName, []);
					}
					groups.get(attributeName).push(ele);
				});
				groups.forEach(group => {
					const attributeGroups = new Map();
					group.forEach(ele => {
						const attributeName = ele.getAttribute('data-mh');
						const topPosition = ele.getBoundingClientRect().top;
						const key = attributeName + '_' + topPosition;
						if (!attributeGroups.has(key)) {
							attributeGroups.set(key, []);
						}
						attributeGroups.get(key).push(ele);
					});
					attributeGroups.forEach((item) => {
						let maxHeight = Math.max(...item.map(ele => ele.offsetHeight));
						item.forEach(ele2 => {
							ele2.style.height = `${maxHeight}px`;
						});
					});
				});
			} else {
				const groups = new Map();
				_elements.forEach(ele => {
					const topPosition = ele.getBoundingClientRect().top;
					if (!groups.has(topPosition)) {
						groups.set(topPosition, []);
					}
					groups.get(topPosition).push(ele);
				});
				groups.forEach((item) => {
					let maxHeight = Math.max(...item.map(ele => ele.offsetHeight));
					item.forEach(ele2 => {
						ele2.style.height = `${maxHeight}px`;
					});
				});
			}
		},
		headerMenu() {
			const f = 'is-fixed';
			const o = 'is-open';
			let $header = document.querySelector('.header');
			let $headerNav = document.querySelector('.header-nav');
			let $headerMegaSwitch = document.querySelectorAll('.header-nav .js-megaMenuOpen');
			let $headerClose = document.querySelectorAll('.js-header-close');
			let $hamburger = document.querySelector('.header-hamburger');
			let $search = document.querySelector('.header-search');
			let $searchBtn = document.querySelectorAll('.js-header-search');
			let $overlay = document.querySelector('.header-overlay');
			let $container = document.querySelector('.container');

			const fixedOnContainer = () => {
				if ($container) {
					scrollTopNow = window.scrollY;
					$container.style.position = 'fixed';
					$container.style.width = '100%';
					$container.style.top = -1 * scrollTopNow;
					$container.style.left = 0;
					document.body.classList.add(f);
				}
			};
			const fixedOffContainer = () => {
				if ($container) {
					$container.style.position = 'static';
					document.body.classList.remove(f);
					window.scrollTo({ top: scrollTopNow });
				}
			};

			if (!FUNCTIONS.va.isEnglish) {
				// PICK UP キャンペーン 消し込み
				if ($header.querySelectorAll('.__item.ng_news').length) {
					$header.querySelectorAll('.__item.ng_news').forEach(ele => {
						if (!ele.getElementsByTagName('a').length) { ele.remove(); }
					});
				}
				if (!$header.querySelectorAll('.__item.ng_news').length) {
					$header.querySelector('.__megaMenu_body.rt_bn_products_campaign_info').remove();
				}
				// おすすめレシピ 消し込み
				if ($header.querySelectorAll('.__item.cf_rsg_recipe_special_feature').length) {
					$header.querySelectorAll('.__item.cf_rsg_recipe_special_feature').forEach(ele => {
						if (!ele.getElementsByTagName('a').length) { ele.remove(); }
					});
				}
				if ($header.querySelector('.__megaMenu_body.rt_bn_osusume_recipe_list') && !$header.querySelectorAll('.__item.cf_rsg_recipe_special_feature').length) {
					$header.querySelector('.__megaMenu_body.rt_bn_osusume_recipe_list').remove();
				}
			}

			// メガメニュー開閉
			const megaMenu = () => {
				$headerMegaSwitch.forEach((ele) => {
					ele.addEventListener('click', () => {
						if (ele.classList.contains(o)) {
							// Close
							ele.classList.remove(o);

							if (FUNCTIONS.va.window.width > FUNCTIONS.va.device.desktop) {
								ele.nextElementSibling.classList.remove(v);
								setTimeout(() => { ele.nextElementSibling.classList.remove(a); }, 400);
								$header.classList.remove(o);
								$overlay.classList.remove(v);
								setTimeout(() => { $overlay.classList.remove(a); }, 400);
								fixedOffContainer();
							} else {
								ele.nextElementSibling.style.height = null;
							}
						} else {
							// Open
							if (FUNCTIONS.va.window.width > FUNCTIONS.va.device.desktop) {
								fixedOnContainer();

								// 他のメニューを閉じる
								$headerMegaSwitch.forEach((ele2) => {
									if (ele2.nextElementSibling.classList.contains(a)) {
										ele2.classList.remove(o);
										ele2.nextElementSibling.classList.remove(v);
										setTimeout(() => { ele2.nextElementSibling.classList.remove(a); }, 400);
									}
								});
								// サイト内検索閉じる
								if ($search.classList.contains(a)) {
									$search.classList.remove(v);
									setTimeout(() => { $search.classList.remove(a); }, 400);
								}
								// オーバーレイが無ければ出す
								if (!$overlay.classList.contains(a)) {
									$overlay.classList.add(a);
									setTimeout(() => { $overlay.classList.add(v); }, 100);
								}
							}

							$header.classList.add(o);
							ele.classList.add(o);
							ele.nextElementSibling.classList.add(a);
							if (FUNCTIONS.va.window.width > FUNCTIONS.va.device.desktop) {
								setTimeout(() => { ele.nextElementSibling.classList.add(v); }, 100);
							} else {
								ele.nextElementSibling.style.height = ele.nextElementSibling.scrollHeight + 'px';
							}
						}
					});
				});
			};
			megaMenu();

			// [SP] ハンバーガーメニュー
			const setHamburger = () => {
				$hamburger.addEventListener('click', (e) => {
					if ($hamburger.classList.contains(a)) {
						// Close
						$headerNav.classList.remove(v);
						$overlay.classList.remove(v);
						setTimeout(() => {
							$headerNav.classList.remove(a);
							$overlay.classList.remove(a);
						}, 400);
						$hamburger.classList.remove(a);
						$header.classList.remove(o);
						$overlay.classList.remove(v);
						setTimeout(() => { $overlay.classList.remove(a); }, 400);
						fixedOffContainer();
					} else {
						// Open
						fixedOnContainer();

						// サイト内検索閉じる
						if (!FUNCTIONS.va.isEnglish) {
							if ($search.classList.contains(a)) {
								$search.classList.remove(v);
								setTimeout(() => { $search.classList.remove(a); }, 400);
							}
						}
						// オーバーレイが無ければ出す
						if (!$overlay.classList.contains(a)) {
							$overlay.classList.add(a);
							setTimeout(() => { $overlay.classList.add(v); }, 100);
						}

						$header.classList.add(o);
						$hamburger.classList.add(a);
						$headerNav.classList.add(a);
						setTimeout(() => { $headerNav.classList.add(v); }, 100);
					}
				});
			};
			setHamburger();

			// [SP] サイト内検索開閉
			const setSiteSearch = () => {
				$searchBtn.forEach((ele) => {
					ele.addEventListener('click', () => {
						if ($search.classList.contains(a)) {
							// Close
							$search.classList.remove(v);
							setTimeout(() => { $search.classList.remove(a); }, 400);
							$header.classList.remove(o);
							$overlay.classList.remove(v);
							setTimeout(() => { $overlay.classList.remove(a); }, 400);
							fixedOffContainer();
						} else {
							// Open
							// ハンバーガーメニューを閉じる
							if ($hamburger.classList.contains(a)) {
								$headerNav.classList.remove(v);
								setTimeout(() => { $headerNav.classList.remove(a); }, 400);
								$hamburger.classList.remove(a);
							}
							// オーバーレイが無ければ出す
							if (!$overlay.classList.contains(a)) {
								$overlay.classList.add(a);
								setTimeout(() => { $overlay.classList.add(v); }, 100);
								fixedOnContainer();
							}

							$header.classList.add(o);
							$search.classList.add(a);
							setTimeout(() => { $search.classList.add(v); }, 100);
						}
					});
				});
			}
			setSiteSearch();

			// [PC] Closeボタンでメガメニューを閉じる
			const closeBtn = () => {
				$headerClose.forEach((ele) => {
					ele.addEventListener('click', () => {
						ele.closest('div').classList.remove(v);
						setTimeout(() => { ele.closest('div').classList.remove(a); }, 400);
						if (ele.closest('div').previousElementSibling.classList.contains(o)) { ele.closest('div').previousElementSibling.classList.remove(o); }
						$header.classList.remove(o);
						$overlay.classList.remove(v);
						setTimeout(() => { $overlay.classList.remove(a); }, 400);
						fixedOffContainer();
					});
				});
			};
			closeBtn();

			// [PC] オーバーレイエリア押下でメガメニューを閉じる
			const closeOverlay = () => {
				$overlay.addEventListener('click', () => {
					$headerMegaSwitch.forEach((ele) => {
						if (ele.classList.contains(o)) {
							ele.classList.remove(o);
							ele.nextElementSibling.classList.remove(v);
							setTimeout(() => { ele.nextElementSibling.classList.remove(a); }, 400);
						}
					});
					if ($search.classList.contains(a)) {
						$search.classList.remove(v);
						setTimeout(() => { $search.classList.remove(a); }, 400);
					}
					$header.classList.remove(o);
					$overlay.classList.remove(v);
					setTimeout(() => { $overlay.classList.remove(a); }, 400);
					fixedOffContainer();
				});
			};
			closeOverlay();

			// [PC] ESCキー押下でメガメニューを閉じる
			const closeESCKey = () => {
				document.addEventListener('keydown', (e) => {
					if (e.key === 'Escape') {
						if ($overlay.classList.contains(a)) {
							$headerMegaSwitch.forEach((ele) => {
								if (ele.nextElementSibling.classList.contains(a)) {
									ele.classList.remove(o);
									ele.nextElementSibling.classList.remove(v);
									setTimeout(() => { ele.nextElementSibling.classList.remove(a); }, 400);
								}
							});
							$header.classList.remove(o);
							$overlay.classList.remove(v);
							setTimeout(() => { $overlay.classList.remove(a); }, 400);
							fixedOffContainer();
						}
					}
				});
			};
			closeESCKey();

			// カレント処理
			let map = FUNCTIONS.va.pathname.split('/')[1];
			switch (map) {
				case 'products': document.querySelector('.js-megaMenuOpen[data-category="products"]').classList.add('is-current'); break;
				case 'enjoy': document.querySelector('.js-megaMenuOpen[data-category="enjoy"]').classList.add('is-current'); break;
				case 'recipes': document.querySelector('.js-megaMenuOpen[data-category="recipes"]').classList.add('is-current'); break;
				case 'hirameki': document.querySelector('.js-megaMenuOpen[data-category="hirameki"]').classList.add('is-current'); break;
				case 'company': document.querySelector('.js-megaMenuOpen[data-category="company"]').classList.add('is-current'); break;
				case 'customer': document.querySelector('.js-megaMenuOpen[data-category="customer"]').classList.add('is-current'); break;
				default: break;
			}
		},
		fileSize() {
			const bytesToSize = (bytes) => {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
			if(document.querySelectorAll('.js-fileSize').length) {
				document.querySelectorAll('.js-fileSize').forEach(function (pdfElement) {
					pdfElement.innerText = bytesToSize(pdfElement.innerText);
				});
			}
		},
		localNav() {
			document.querySelectorAll('.mod-localNav').forEach(ele => {
				let activeFlg = false;
				ele.querySelectorAll('a').forEach(link => {
					if (link.getAttribute('href') === FUNCTIONS.va.pathname) {
						link.classList.add('is-active');
						activeFlg = true;
					}
				});
				if (!activeFlg) {
					ele.querySelectorAll('a').forEach(link => {
						if (FUNCTIONS.va.pathname.indexOf(link.getAttribute('href')) !== -1) {
							link.classList.add('is-active');
						}
					});
				}
			});
		},
		oldLocalNav() { // 旧ソースの上部ナビ SPでアコーディオン
			let navTitle = document.querySelector('.c-tab--navigation__title');
			let navTitleLink = navTitle.querySelector('.c-tab--navigation__title__link');
			let navContent = document.querySelector('.c-tab--navigation .c-tab__items');

			const slideUp = (el, duration = 300) => {
				el.style.height = el.offsetHeight + 'px';
				el.offsetHeight;
				el.style.transitionProperty = 'height, margin, padding';
				el.style.transitionDuration = duration + 'ms';
				el.style.transitionTimingFunction = 'ease';
				el.style.overflow = 'hidden';
				el.style.height = 0;
				el.style.paddingTop = 0;
				el.style.paddingBottom = 0;
				el.style.marginTop = 0;
				el.style.marginBottom = 0;
				setTimeout(() => {
					el.style.display = 'none';
					el.style.removeProperty('height');
					el.style.removeProperty('padding-top');
					el.style.removeProperty('padding-bottom');
					el.style.removeProperty('margin-top');
					el.style.removeProperty('margin-bottom');
					el.style.removeProperty('overflow');
					el.style.removeProperty('transition-duration');
					el.style.removeProperty('transition-property');
					el.style.removeProperty('transition-timing-function');
				}, duration);
			};
			const slideDown = (el, duration = 300) => {
				el.style.removeProperty('display');
				let display = window.getComputedStyle(el).display;
				if (display === 'none') {
					display = 'block';
				}
				el.style.display = display;
				let height = el.offsetHeight;
				el.style.height = 0;
				el.style.paddingTop = 0;
				el.style.paddingBottom = 0;
				el.style.marginTop = 0;
				el.style.marginBottom = 0;
				el.offsetHeight;
				el.style.transitionProperty = 'height, margin, padding';
				el.style.transitionDuration = duration + 'ms';
				el.style.transitionTimingFunction = 'ease';
				el.style.overflow = 'hidden';
				el.style.height = height + 'px';
				el.style.removeProperty('padding-top');
				el.style.removeProperty('padding-bottom');
				el.style.removeProperty('margin-top');
				el.style.removeProperty('margin-bottom');
				setTimeout(() => {
					el.style.removeProperty('height');
					el.style.removeProperty('overflow');
					el.style.removeProperty('transition-duration');
					el.style.removeProperty('transition-property');
					el.style.removeProperty('transition-timing-function');
				}, duration);
			};
			const linkSet = () => {
				if (getComputedStyle(navContent).display === 'flex') {
					navTitleLink.classList.remove('disabled');
				} else {
					navTitleLink.classList.add('disabled');
				}
			};
			linkSet();
			window.addEventListener('resize', () => { linkSet(); });

			navTitle.addEventListener('click', e => {
				if (getComputedStyle(navContent).display !== 'flex') {
					navTitle.setAttribute('aria-selected', navTitle.getAttribute('aria-selected') === 'false' ? 'true' : 'false');

					if (navTitle.getAttribute('aria-selected') === 'true') {
						navTitle.querySelector('span').classList.remove('c-icon__minus')
						navTitle.querySelector('span').classList.add('c-icon__plus');
						slideUp(navContent);
					}
					else {
						navTitle.querySelector('span').classList.remove('c-icon__plus')
						navTitle.querySelector('span').classList.add('c-icon__minus');
						slideDown(navContent);
					}
				}
			});
			navTitleLink.addEventListener('click', e => {
				if (e.currentTarget.classList.contains('disabled')) {
					e.preventDefault();
				}
			});
		},

		loadDelayScript() {
			let _this = this;
			_this.accordion();
			_this.pulldown();
			_this.tabSwitch();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.setModal();
			_this.matchHeight();
			_this.headerMenu();
			_this.fileSize();
			_this.localNav();

			let resizeTime = 0;
			window.addEventListener('resize', () => {
				clearTimeout(resizeTime);
				resizeTime = setTimeout(() => { _this.matchHeight(); }, 300);
			});

			if (document.getElementsByClassName('c-tab--navigation').length) { _this.oldLocalNav(); }
		}
	};

	document.addEventListener('DOMContentLoaded', () => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})();
